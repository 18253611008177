@use 'sass:math';

// Spot Mono Grid
// 600u wide, 1000upm
// (15pt * 600) / 1000 = 9pt wide grid
$grid_width: 9px;
$grid_height: 18px;
$nav_rows: 2;

$faces_min: 30;
$faces_max: 60;

@function fibonacci($n) {
  $fib: 0 1;
  @for $i from 1 through $n {
    $new: nth($fib, length($fib)) + nth($fib, length($fib) - 1);
    $fib: append($fib, $new);
  }
  @return $fib;
}

$fib: fibonacci(20);

/* Settings */
:root {
    --offset: #{$grid_width};
    --columns: 6;
    --gutter: 0px;
    --padding: #{$grid_width * 2};
    --baseline: #{$grid_height};
    --baseline-shift: 0;
    // --baseline-shift: calc(var(--baseline) / 2);
    --line-thickness: 1px;
    --color: #1c2c42;
    --color-text: #fff;
    --media-query: 'base';
    --nav-height: #{$nav_rows * $grid_height};
  }

@for $i from 20 through 300 {
  @media (min-width: #{$i * $grid_width}) {
    :root {
      --columns: #{$i};
      $p: math.clamp(4, math.floor(math.div($i,40)) + 3, 20);
      --padding: #{nth($fib, $p) * $grid_width};
      $main: $i - $p;
    }
  }
}

@for $i from 3 through 100 {
  @media (min-height: #{$i * $grid_height}) {
    :root {
      --media-query: '#{$i}';
      $face_height: math.clamp($faces_min, $i - 3, $faces_max) * $grid_height;
      $faces_rows: math.div($face_height, $grid_height);
      --face-url: url("../faces/face-#{$faces_rows}.svg");
      --face-height: #{$face_height};
    }
  }
}



/* Helper variables */
:root {
  --repeating-width: calc(100% / var(--columns));
  --column-width: calc((100% / var(--columns)) - var(--gutter));
  --background-width: calc(100% + var(--gutter));
  --background-columns: repeating-linear-gradient(
    to right,
    var(--color),
    var(--color) var(--line-thickness),
    transparent var(--line-thickness),
    transparent calc(var(--column-width) - var(--line-thickness)),
    var(--color) calc(var(--column-width) - var(--line-thickness)),
    var(--color) var(--column-width),
    transparent var(--column-width),
    transparent var(--repeating-width)
  );
  --background-baseline: repeating-linear-gradient(
    to bottom,
    var(--color),
    var(--color) 1px,
    transparent 1px,
    transparent var(--baseline)
  );
}

nav {
  position: relative;
  padding-left: var(--padding);
  padding-right: var(--padding);
  margin: 0;
  height: var(--nav-height);
}

main {
  margin-top: #{$grid_height};
  margin-left: auto;
  padding-left: var(--padding);
  margin-right: auto;
  padding-right: var(--padding);
  display: block;
  #face {
    width: auto;
    height: var(--face-height);
    background-image: var(--face-url);
    background-repeat: no-repeat;
  }
}

html {
  position: relative;
  margin-top: #{ 0.5 * $grid_height};
}

// html::before {
//   position: absolute;
//   top: 0; right: 0; bottom: 0; left: 0;
//   margin-right: auto;
//   margin-left: auto;
//   width: 100%;
//   min-height: 100vh;
//   content: '';
//   background-image: var(--background-columns), var(--background-baseline);
//   background-size: var(--background-width) 100%;
//   background-position: 0 var(--baseline-shift);
//   z-index: 1000;
//   pointer-events: none;
// }

// html::after {
//   content: var(--media-query);
//   position: fixed;
//   bottom: 1rem;
//   left: 1rem;
//   font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
//   color: var(--color-text);
//   z-index: 1001;
// }
